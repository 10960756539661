@import url(https://fonts.googleapis.com/css?family=Quicksand);
.home {
  width: 100vw;
  height: calc(100vh - 140.5px);
  background-image: url("/images/Konrad_and_dogs.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: top 20px left 30%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* border: 1px solid red; */
}
@media (min-width: 600px) {
  .home {
    height: calc(100vh - 127px);
    background-position: 30% 30%;
  }
}

.about { 
  display:flex;
  flex-flow: column;

  min-height: calc(100vh - 7.7em);
  background-color: #eee;
}
.about .content {
  margin: 0.4em;
}

.selina { 
  display:flex;
  flex-flow: column;

  min-height: calc(100vh - 7.7em);
  background-color: #eee;
}
.selina .content {
  margin: 0.4em;
}

.personal { 
  display:flex;
  flex-flow: column;

  min-height: calc(100vh - 7.7em);
  background-color: #eee;
}
.personal .content {
  margin: 0.4em;
}

.history { 
  display:flex;
  flex-flow: column;

  min-height: calc(100vh - 7.7em);
  background-color: #eee;
}
.history .content {
  margin: 0.4em;
}

.certificates { 
  display:flex;
  flex-flow: column;

  min-height: calc(100vh - 7.7em);
  background-color: #eee;
}
.certificates .content {
  margin: 0.4em;
}

.pricing { 
  display:flex;
  flex-flow: column;

  min-height: calc(100vh - 7.7em);
  background-color: #eee;
}
.pricing .content {
  margin: 0.4em;
}

.slide-enter {
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
  transition: 1s ease-in-out;
}

.slide-exit {
  position: fixed;
  width: 100%;
  height: 100%;
  transform: translateX(0%);
}

.slide-exit.slide-exit-active {
  transform: translateX(-100%);
  transition: 1s ease-in-out;
}

.App-header {
  /* border: 1px solid black; */
}
header {
  height: 104px;
  /* height: 6.5em; */
  background-color: white;
  display: flex;
  flex-flow: row wrap;
}

header * {
  color: rgb(230, 109, 132);/* contrast ratio 3.05 */
}

.header-title {
  flex: 1 1 100%;

  text-align: center;
  margin-top: 0px;
  margin-bottom: -20px;
}
.header-title img {
  width: 170px;
}

.header-title a {
  text-decoration: none;
  font-size: 300%;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
}

.header-menu {
  flex: 1 1 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 15px;
  margin-top: 7px;
}

a.header-menu-item,
label.header-menu-item {
  font-size: 1.4em;
  line-height: 1.3;
  padding: 5px;
  text-decoration: none;
}

a.header-menu-item.active,
.ddm.active > label {
  text-decoration: underline;
}

.ddm.active > label {
  padding: 9px 5px;
}

.header-menu .ddm {
  position: relative;
}
.header-menu .ddm > input[type=checkbox] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
a.header-menu-item:hover,
.header-menu .ddm > input[type=checkbox]:hover + label {
  background: #eee;
  border-radius: 0.5em;
}

.ddm .dd {
  position:absolute;
  right: 0px;
  background:white;
  height:0px;
  transition: height 0.4s ease;
  overflow:hidden;
  z-index: 100;
  opacity: 1.0;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.ddm > input[type=checkbox]:checked ~ div.dd {
  height: 12em;
}

.ddm div.overlay {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;  
  /* border: 1em solid gray; */
  z-index: 99;
}
.ddm > input[type=checkbox]:checked ~ div.overlay {
  width: 100%;
  height: 100%;  
}
.ddm > .dd a {
  font-size: 1.4em;
  padding: 0 12px;
  text-decoration: none;
  text-align: right;
  line-height: 1.9;
}
.ddm > .dd a:hover,
.ddm > .dd a.active {
  background: #eee;
}
.ddm > .dd a.active {
  text-decoration: underline;
}

.header-menu .ddm > input[type=checkbox] ~ label ~ i {
  transition: 0.4s ease;
}
.header-menu .ddm > input[type=checkbox]:checked ~ label ~ i {
  transform: rotate(90deg);
}
.header-menu .ddm > input[type=checkbox]:not(:checked) ~ label ~ i {
  transform: rotate(0deg);
}

footer {
  display: flex;
  flex-flow: row wrap;
  background: linear-gradient(#777, #444);
  color: white;
  font-size: 0.7em;
  padding: 0.2em;
  /* min-height: 1.3em; */
  min-height: 32.5px;
}
@media (min-width: 600px) {
  footer {
    min-height: 19px;
  }
}

.images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.4em;
}

.image {
  display: block;
  width: 96vw;
  height: 100%; /* IE11 */
  padding-bottom: 1em;
}

.image1 {
  width: 100%;
}

@media only screen 
and (min-width: 768px) 
and (max-width: 1024px) {
  .image {
    width: 70vw;
  }
}
@media only screen 
and (min-width: 1024px) {
  .image {
    width: 52vw;
  }
}

body {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  height: 100vh;
}

