.certificates { 
  display:flex;
  flex-flow: column;

  min-height: calc(100vh - 7.7em);
  background-color: #eee;
}
.certificates .content {
  margin: 0.4em;
}
