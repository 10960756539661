.home {
  width: 100vw;
  height: calc(100vh - 140.5px);
  background-image: url("/images/Konrad_and_dogs.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-position: top 20px left 30%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* border: 1px solid red; */
}
@media (min-width: 600px) {
  .home {
    height: calc(100vh - 127px);
    background-position: 30% 30%;
  }
}
